import Box from "@material-ui/core/Box";
import { Grid, Paper, Link, Container } from "@material-ui/core";

import LandingCard from "../../cards/LandingCard";
import FullscreenVideo from "../../video/FullscreenVideo";
import { makeStyles } from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  paperSidebar: {
    background: "transparent",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "25px",
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify: "space-between",
  },
  sidebar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    writingMode: "vertical-lr",
    flexGrow: "4",
  },
  showcaseConatiner: {
    height: "calc(100vh - 84px)",
    width: "100vw",
    maxWidth: "100%",
  },
  landingCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },
}));

export default function LandingSection() {
  const classes = useStyles();
  return (
    <>
      <FullscreenVideo />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        className={classes.showcaseConatiner}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          item
          xs={1}
        >
          <Grid item xs={1}>
            <Paper className={classes.paperSidebar} elevation={0}>
              <Link
                className={classes.sidebar}
                style={{ marginTop: "200px" }}
                href="mailto:grant@grantdesigns.ie"
                color="textPrimary"
                underline="none"
                target="_blank"
              >
                Email Us
              </Link>

              <Link
                className={classes.sidebar}
                style={{ marginBottom: "50px" }}
                href="tel:+353862431811"
                color="textPrimary"
                underline="none"
                target="_blank"
              >
                Call Us
              </Link>
              <Box variant="span" className={classes.icons}>
                <Link
                  href="https://www.facebook.com/GrantDesigns16/"
                  color="textPrimary"
                  target="_blank"
                >
                  <FacebookIcon />
                </Link>
                <Link
                  href="https://twitter.com/@GrantdesignsMac"
                  color="textPrimary"
                  target="_blank"
                >
                  <TwitterIcon />
                </Link>
                <Link
                  href="https://www.linkedin.com/in/GrantMacEwanisGrantDesigns"
                  color="textPrimary"
                  target="_blank"
                >
                  <LinkedInIcon />
                </Link>
                <Link
                  href="https://instagram.com/grantdesignstudio"
                  color="textPrimary"
                  target="_blank"
                >
                  <InstagramIcon />
                </Link>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Box variant="span" className={classes.landingCard}>
            <LandingCard />
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
}
