import mp4 from "../../assets/videos/introC.mp4";
export default function FullscreenVideo() {
  return (
    <div>
      <video id="background-video" autoPlay muted>
        <source src={mp4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
