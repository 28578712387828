import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  ctaButton: {
    background:
      "linear-gradient(to  right, #662e19 0%, #eda460 50%, #d6915a 100%)",
    color: "#ffffff",
    width: "250px",
    alignSelf: "center",
  },
}));

export default function CTAButton() {
  const classes = useStyles();
  return (
    <Button variant="contained" disableElevation className={classes.ctaButton}>
      Explore
    </Button>
  );
}
