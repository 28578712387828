import { Card, Typography, CardContent, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CTAButton from "../buttons/CTAButton";

const useStyles = makeStyles(() => ({
  landingCard: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ctabutton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const LandingCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.landingCard} elevation={0}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" align="center">
          SCULPTURE
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
          align="center"
        >
          BESPOKE DESIGN IN TIMBER, STAINLESS STEEL & COPPER
        </Typography>
      </CardContent>
      <CardActions className={classes.ctabutton}>
        <CTAButton />
      </CardActions>
    </Card>
  );
};

export default LandingCard;
