import clsx from "clsx";
import { useRef, useEffect, useState } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import pattern from "./../../../assets/pattern.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const items = [
  {
    heading: "Yacht Models",
    subtitle: "it is a very nice model",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.",
    image: require("./../../../assets/collections/ym1.jpg"),
  },

  {
    heading: "Garden Fires",
    subtitle: "it is a very nice Fire",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.",
    image: require("./../../../assets/collections/firecolor.png"),
  },
  {
    heading: "Chopping Board",
    subtitle: "it is a very nice chopping board",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.",
    image: require("./../../../assets/collections/board.jpg"),
  },
  {
    heading: "Sculpture",
    subtitle: "it is a very nice Fire",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.",
    image: require("./../../../assets/collections/SC.jpg"),
  },
  {
    heading: "Bespoke Comissions",
    subtitle: "it is a very nice Commission",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos  blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.",
    image: require("./../../../assets/collections/BC.jpg"),
  },
];

const useStyleMain = makeStyles((theme) => ({
  collectionConatiner: {
    minHeight: "100vh",
    maxWidth: "100%",
    margin: "0",
  },
  panel: {
    minHeight: "100vh",
  },
  flexcontainer: {
    display: "flex",
    flexDirection: "row",
  },
}));

const useStylesHeader = makeStyles((theme) => ({
  collectionContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    allignItems: "center",
  },
  sectionTitle: {
    textAlign: "center",
    color: "white",
  },
  header: {
    padding: "20px",
  },

  infoHolder: {
    color: "#ffff",
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    allignItems: "center",
    height: "100%",
  },

  gridcontainer: {
    display: "grid",
    gridTemplateColumns:
      "repeat(3, minmax(0, 0.4fr)) repeat(6, 2fr) repeat(3, 0.2fr) ",
    gridTemplateRows: " 0.5fr 2fr 2fr 2fr 0.5fr ",
    gridTemplateAreas:
      "'. . . header header header header header header . . . '" +
      "'. . . info info info img img img  . . . '" +
      "'. col . info info info img img img  . . .'" +
      " '. . . info info info img img img  . . .'" +
      " '. . . . . . . . . . . .'",
    height: "100vh",
    maxWidth: "100%",
    rowGap: "2rem",
    columnGap: "2rem",
    background: `#000000 url(${pattern})`,
  },
  gridHeader: {
    gridArea: "header",
  },

  girdInfo: {
    gridArea: "info",
    margin: "auto 0",
  },
  gridImg: {
    width: "auto",
    gridArea: "img",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block",
  },
  sideinfo: {
    justifySelf: "start",
    gridArea: "col",
    maxWidth: "100%",
  },
  sidetext: {
    color: "white",
    transform: "rotate(90deg)",
    fontSize: "3rem",
  },
}));

function FirstCollection(props) {
  const classes = useStylesHeader();

  const img = clsx(classes.img, "sectionImg");

  return (
    <>
      <div className={classes.gridcontainer}>
        <div className={classes.sideinfo}>
          <Typography className={classes.sidetext} variant="body2" gutterBottom>
            Collections
          </Typography>
        </div>
        <div className={classes.girdInfo}>
          <Typography variant="h3" gutterBottom>
            {props.heading}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {props.subtitle}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {props.description}
          </Typography>
        </div>
        <div className={classes.gridImg}>
          <img className={img} src={props.image} />
        </div>
      </div>
    </>
  );
}

export default function CollectionsSection() {
  const classes = useStyleMain();
  const panelRefs = useRef([]);
  panelRefs.current = [];
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    console.log(panelRefs.current.length);
    panelRefs.current.forEach((el, index) => {
      if (index == 4) {
        console.log(el);
        ScrollTrigger.create({
          trigger: el,
          start: "top top",
          end: "bottom",
          pin: true,

        });
      } else {
        ScrollTrigger.create({
          trigger: el,
          start: "top top",
          pin: true,
          pinSpacing: false,
          scrub: 3,
          snap: {
            snapTo: 1,
            duration: { min: 0.3, max: 0.7 },
            ease: "power2.inOut",
            anticipatePin: 0.2,
            delay: 0,
            number: 4,
          },

        });
      }
    });
  }, []);

  const addToPanelRefs = (el) => {
    if (el && !panelRefs.current.includes(el)) {
      panelRefs.current.push(el);
    }
  };
  return (
    <section className="collections">
      <div className={classes.flexcontainer}>
        <div className={classes.collectionConatiner}>
          {items.map((i) => (
            <div className="panel" ref={addToPanelRefs}>
              <FirstCollection
                key={i.heading}
                heading={i.heading}
                subtitle={i.subtitle}
                description={i.description}
                image={i.image}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
