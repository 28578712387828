import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navbar from "../components/navbar/Navbar2";
import Footer from "../components/footer/Footer";
const useStyles = makeStyles({
  page: {
    background: "#4d4d4f",
    width: "100%",
  },
});

export default function Layout({ children }) {
  const classes = useStyles;
  return (
    <CssBaseline>
      <Navbar />

      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
      <Footer />
    </CssBaseline>
  );
}
