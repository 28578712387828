import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";

function pxToRem(value) {
  return `${value / 16}rem`;
}
const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#c7c8ca",
      main: "#6d6e71",
      dark: "#4d4d4f",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#eda460",
      main: "#d6915a",
      dark: "#662e19",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#eda460",
      disabled: "#c7c8ca",
    },
  },

  typography: {
    fontFamily: "alverata-informal, serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },

  overrides: {
    MuiToolbar: {
      regular: {
        height: "64px",
        minHeight: "64px",
        '@media(min-width:600px)' : {
          minHeight:"64px"
        }
      }
    }
  }

});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
