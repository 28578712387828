import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { useState } from "react";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {
  Avatar,
  Drawer,
  Divider,
  Box,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import logo from "./../../assets/logo/logo.png";

const breakpoints = createBreakpoints({});
const drawerWitdh = 240;
const useStyles = makeStyles((theme) => ({
  nav: {
    background: "rgba(0,0,0,0)",
    boxShadow: "none",
    marginTop: "20px",
    maxWidth: "100%",
  },

  flexList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.down("md")]: {
      flex: "1",
    },
    width: "100%",
  },
  logo: {
    height: "60px",
    width: "153px",
  },
  logoHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    color: "#EEEEEE",
  },

  listItem: {
    fontWeight: "700",
    textTransform: "uppercase",
  },
  menuButton: {
    color: "#EEEEEE",
    marginLeft: "25px",
  },
  drawer: {
    width: drawerWitdh,
  },
  drawerPaper: {
    width: drawerWitdh,
    color: "#4d4d4f",
  },
  menuItems: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const [menuState, setMenuState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    setMenuState(open);
  };

  const leftMenuItems = [
    {
      text: "News",
      path: "/",
    },
    {
      text: "Grant's Story",
      path: "/",
    },
    {
      text: "Commissions",
      path: "/",
    },
  ];
  const rightMenuItems = [
    {
      text: "Store",
      path: "/",
    },
    {
      text: "Testimonials",
      path: "/",
    },
    {
      text: "Contact Us",
      path: "/",
    },
  ];
  const OutputMenuItems = (props) => {
    return props.menu.map((item) => (
      <Grid item md={3} key={item.text} className={classes.menuItems}>
        {item.text}
      </Grid>
    ));
  };

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <AppBar position="static" className={classes.nav}>
      <Toolbar disableGutters className={classes.flexList}>
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={menuState}
          onClose={toggleDrawer(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <Grid item className={classes.menuItems}>
            Menu
          </Grid>
          <Divider />
          <OutputMenuItems menu={leftMenuItems} />
          <OutputMenuItems menu={rightMenuItems} />
        </Drawer>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={5}>
            {isMatch ? (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item md={3} className={classes.menuItems}>
                  <AccountCircleIcon />
                </Grid>
                <OutputMenuItems menu={leftMenuItems} />
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.logoHolder}>
              <Avatar
                variant="square"
                src={logo}
                alt="Logo"
                className={classes.logo}
              />
            </Box>
          </Grid>

          <Grid item xs={5}>
            {isMatch ? (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <OutputMenuItems menu={rightMenuItems} />
                <Grid item xs={3} className={classes.menuItems}>
                  <ShoppingCartIcon />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
