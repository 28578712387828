import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core";
import { TextField, FormControl, FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  ctaButton: {
    background:
      "linear-gradient(to  right, #662e19 0%, #eda460 50%, #d6915a 100%)",
    color: "#ffffff",
    width: "250px",
    height: "100%",
    alignSelf: "center",
    padding: "18px",
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
    color: "secondary",
  },
  textfieldtext: {
    color: "black",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
}));

import tw from "./../../assets/pages/twitter.svg";
import fb from "./../../assets/pages/facebook.svg";
import insta from "./../../assets/pages/insta.svg";
import yt from "./../../assets/pages/youtube.svg";
import gd from "./../../assets/pages/logo-silver.svg";

export default function Footer() {
  const classes = useStyles();
  return (
    <footer id="footer">
      <div className="newsletter-area">
        <div className="box"></div>
        <div className="container">
          <div className="newsletter-card">
            <div className="newsletter-holder">
              <strong className="title">Subscribe Newsletters</strong>
              <div className="newsletter-form">
                <FormControl className={classes.form}>
                  <TextField
                    className={classes.field}
                    label="email address"
                    variant="outlined"
                    color="secondary"
                    InputProps={{
                      className: classes.textfieldtext,
                    }}
                  >
                    {" "}
                  </TextField>

                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.ctaButton}
                  >
                    Subcribe Now
                  </Button>
                </FormControl>

              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-nav-holder">
          <div className="col">
            <nav className="footer-nav">
              <ul>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Discover</a>
                </li>
                <li>
                  <a href="#">Explore</a>
                </li>
                <li>
                  <a href="#">Books</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col">
            <nav className="social-nav">
              <ul>
                <li>
                  <a href="#">
                    <img src={fb} alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={tw} alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={insta} alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={yt} alt="" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="support-links-holder">
          <div className="logo">
            <a href="#">
              <img src={gd} alt="" />
            </a>
          </div>
          <div className="copyright-holder">
            <span className="copyright">
              © 2019 Lift Media. All rights reserved.
            </span>
          </div>
          <ul className="support-links">
            <li>
              <a href="#">Terms of Service</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
