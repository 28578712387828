import LandingSection from "../components/sections/homepage/LandingSection";
import CollectionsSection from "../components/sections/homepage/CollectionsSection";
import GrantsStory from "../components/sections/homepage/GrantsStory";
export default function HomePage() {
  return (
    <div>
      <LandingSection />
      <CollectionsSection />
      <GrantsStory />
    </div>
  );
}
